import React from "react"

import Index from "../../components/index"
import SEO from "../../components/other/seo"
import Container from "../../components/layout/container";
import {Link} from 'gatsby';
import {
  Collapsible,
  CollapsibleTitle,
  CollapsibleContent,
} from "../../components/elements/collapsible";
import HandSanitizerImage from "../../components/images/hand-sanitizer";
import Video from "../../components/elements/video";
import MagnifiedViewCrystalImage from "../../components/images/magified-view-crystal";

import {
  FaChevronRight,
} from "react-icons/all";

const HandSanitizerPage = () => (
  <Index>
    <SEO title="8-HOUR DEFENSE Hand Sanitizer" />
    <Container id="product-overview-banner">
      <h1>8-HOUR DEFENSE Hand Sanitizer</h1>
    </Container>
    <Container id="product-details">
      <div className="left">
        <HandSanitizerImage />
      </div>
      <div className="right">
        <h1>
          8-HOUR DEFENSE<br />
          Hand Sanitizer
        </h1>
        <p>
          <b>NDC Identification:</b> 72513-100<br />
          <b>EPA Registration Number:</b> XXXX
        </p>
        <hr />
        <p>After several clinical trials, the 8‐HOUR DEFENSE hand sanitizer safely performs as a “breathable” barrier against unwanted, harmful pathogens.</p>
        <p>The EPA designated 8‐HOUR DEFENSE hand sanitizer is a ground‐breaking solution for killing germs on skin contact for eight hours or more*. 8‐HOUR DEFENSE hand sanitizer safely bonds to your skin for long‐lasting protection against 99.99% of deadly viruses.</p>
        <p>The 8‐HOUR DEFENSE hand sanitizer is FDA registered, kills germs on contact without the use of alcohol or harmful chemicals, and leaves a thin barrier on the skin to guard against infection.</p>
        <p><em>*Skin cells may be shed prior to eight hours naturally or by abrasion. Reapply as needed.</em></p>
        <p>
          <Link to="/order" className="button bordered">Order</Link>
        </p>
        <hr />
        <Collapsible>
          <CollapsibleTitle>Additional Benefits</CollapsibleTitle>
          <CollapsibleContent>
            <p>
              Safest EPA toxicity category IV rating for human exposure<br />
              Promotes healing<br />
              Extended protection<br />
              Soothing foam formula<br />
              Fragrance‐free<br />
              Wont’ dry or crack skin<br />
              Won’t burn open cuts
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Available Sizes</CollapsibleTitle>
          <CollapsibleContent>
            <p>
              1 gallon jug<br />
              1 liter disposable refill bag<br />
              1 liter pump bottle<br />
              500 mL pump bottle<br />
              2 oz spray bottle
            </p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>How to Apply</CollapsibleTitle>
          <CollapsibleContent>
            <p>Check our <Link to="/how-to">How-To</Link> section for detailed instructions on how to use the products.</p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Handling & Storage</CollapsibleTitle>
          <CollapsibleContent>
            <p>Keep in a closed container when not in use. Store away from heat and direct sunlight. Recommended temperature is between 10°C and 40°C. Avoid freezing contents.</p>
          </CollapsibleContent>
        </Collapsible>
        <Collapsible>
          <CollapsibleTitle>Safety Data Sheet</CollapsibleTitle>
          <CollapsibleContent>
            <p><em>Coming Soon</em></p>
          </CollapsibleContent>
        </Collapsible>
        <p>&nbsp;</p>
      </div>
    </Container>
    <Container>
      <div className="product-info-thumb">
        <div className="left">
          <MagnifiedViewCrystalImage />
        </div>
        <div className="right">
          <h3>
            <i><FaChevronRight /></i><br />
            Magnified view of crystal spiked<br />
            barrier that kills harmful viruses<br />
            and bacteria on contact.
          </h3>
        </div>
      </div>
    </Container>
    <Container id="product-training-videos">
      <div className="left" />
      <div className="right">
        <h2>Training</h2>
        <p>How to apply hand sanitizer</p>
        <Video title="Video" videoID="HtZNk60ntKw" />
      </div>
    </Container>
  </Index>
)

export default HandSanitizerPage

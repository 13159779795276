import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HandSanitizerImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "products/hand-sanitizer.png" }) {
        childImageSharp {
          fluid(maxHeight: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img alt="8-HOUR DEFENSE Hand Sanitizer" fluid={data.placeholderImage.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} />
}

export default HandSanitizerImage
